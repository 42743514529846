import { CheckAll } from 'components'

function JoiningRequests () {
  const mainCheckbox = document.getElementById('select-all')

  if (!mainCheckbox) return

  CheckAll({
    mainCheckbox,
    container: mainCheckbox.closest('table')
  })

  const form = document.getElementById('joining-requests-form')
  const approveSelected = document.getElementById('approve-selected')
  const rejectSelected = document.getElementById('reject-selected')

  const toggleSubmit = () => {
    const data = new FormData(form)
    const entries = Array.from(data.entries())

    const disabled = entries.length <= 1

    approveSelected.disabled = disabled
    rejectSelected.disabled = disabled
  }

  form.addEventListener('change', toggleSubmit)

  form.addEventListener('click', ({ target }) => {
    if (target.type === 'button' && target.value === 'approve') {
      form.querySelectorAll('input[type=checkbox]').forEach((checkbox) => {
        checkbox.checked = false
      })
      target.closest('tr').querySelector('input[type=checkbox]').checked = true
      toggleSubmit()
      setTimeout(() => {
        approveSelected.click()
      }, 100)
    }
    if (target.type === 'button' && target.value === 'reject') {
      form.querySelectorAll('input[type=checkbox]').forEach((checkbox) => {
        checkbox.checked = false
      })
      target.closest('tr').querySelector('input[type=checkbox]').checked = true
      toggleSubmit()
      setTimeout(() => {
        rejectSelected.click()
      }, 100)
    }
  })
}

export default JoiningRequests
